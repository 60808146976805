<template>
  <b-sidebar
    id="sidebar-invoice-add-new-customer"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          Add client
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <b-form
        class="p-2"
        @submit.prevent
      >

        <!-- Customer Name -->
        <b-form-group
          label="Nom entreprise / client"
        >
          <validation-provider
            #default="{ errors }"
            name="nom"
          >
            <b-form-input
              v-model="customer.company_name"
              :state="errors.length > 0 ? false : null"
              placeholder="Nom"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Email"
          label-for="email"
        >
          <validation-provider
            #default="{ errors }"
            name="email"
            rules="email"
          >
            <b-form-input
              id="email"
              v-model="customer.email"
              placeholder="Email"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label="Type"
          label-for="type"
        >
          <validation-provider
            #default="{ errors }"
            name="type"
            rules="required"
          >
            <v-select
              v-model="customer.type"
              :clearable="false"
              label="Type"
              :options="types"
              placeholder="Type"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!-- Email -->
        <b-form-group
          label="Numéro mobile"
        >
          <validation-provider
            #default="{ errors }"
            name="mobile"
          >
            <b-form-input
              v-model="customer.mobile"
              :state="errors.length > 0 ? false : null"
              placeholder="Mobile"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Address -->
        <b-form-group
          label="Numéro fix"
        >
          <validation-provider
            #default="{ errors }"
            name="phone"
          >
            <b-form-input
              v-model="customer.phone"
              :state="errors.length > 0 ? false : null"
              placeholder="Numéro fix"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- mobile -->

        <b-form-group
          label="website"
        >
          <validation-provider
            #default="{ errors }"
            name="website"
          >
            <b-form-input
              v-model="customer.website"
              :state="errors.length > 0 ? false : null"
              placeholder="Site web"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Fax"
        >
          <validation-provider
            #default="{ errors }"
            name="fax"
          >
            <b-form-input
              v-model="customer.fax"
              :state="errors.length > 0 ? false : null"
              placeholder="Fax"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label="Adresse"
        >
          <validation-provider
            #default="{ errors }"
            name="address"
          >
            <b-form-input
              v-model="customer.address"
              :state="errors.length > 0 ? false : null"
              placeholder="adresse"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="MF/ CIN"
        >
          <validation-provider
            #default="{ errors }"
            name="fax"
          >
            <b-form-input
              v-model="customer.tax_identification_number"
              :state="errors.length > 0 ? false : null"
              placeholder="MF/ CIN"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            @click="addCustomer"
          >
            Add
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="hide"
          >
            Cancel
          </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>
<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    ValidationProvider,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      types: [
        'client physique',
        'client morale',
      ],
      customer: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        address: '',
        website: '',
        fax: '',
        mobile: '',
        type: '',
        tax_identification_number: '',
      },
      required,
    }
  },
  methods: {
    async addCustomer() {
      await axios
        .post(
          '/customers/create/',
          {
            company_name: this.customer.company_name,
            first_name: this.customer.first_name,
            last_name: this.customer.last_name,
            email: this.customer.email,
            phone: this.customer.phone,
            address: this.customer.address,
            website: this.customer.website,
            fax: this.customer.fax,
            mobile: this.customer.mobile,
            type: this.customer.type,
            tax_identification_number: this.customer.tax_identification_number,
          },
        ).then(() => {
          window.location.reload()
        })

      // vm.$forceUpdate()
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },

}
</script>

  <style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
  </style>
