var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"sidebar-invoice-add-new-customer","sidebar-class":"sidebar-lg","bg-variant":"white","shadow":"","backdrop":"","no-header":"","right":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Add client ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{attrs:{"label":"Nom entreprise / client"}},[_c('validation-provider',{attrs:{"name":"nom"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Nom"},model:{value:(_vm.customer.company_name),callback:function ($$v) {_vm.$set(_vm.customer, "company_name", $$v)},expression:"customer.company_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","placeholder":"Email","state":errors.length > 0 ? false : null},model:{value:(_vm.customer.email),callback:function ($$v) {_vm.$set(_vm.customer, "email", $$v)},expression:"customer.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Type","label-for":"type"}},[_c('validation-provider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"clearable":false,"label":"Type","options":_vm.types,"placeholder":"Type"},model:{value:(_vm.customer.type),callback:function ($$v) {_vm.$set(_vm.customer, "type", $$v)},expression:"customer.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Numéro mobile"}},[_c('validation-provider',{attrs:{"name":"mobile"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Mobile"},model:{value:(_vm.customer.mobile),callback:function ($$v) {_vm.$set(_vm.customer, "mobile", $$v)},expression:"customer.mobile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Numéro fix"}},[_c('validation-provider',{attrs:{"name":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Numéro fix"},model:{value:(_vm.customer.phone),callback:function ($$v) {_vm.$set(_vm.customer, "phone", $$v)},expression:"customer.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"website"}},[_c('validation-provider',{attrs:{"name":"website"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Site web"},model:{value:(_vm.customer.website),callback:function ($$v) {_vm.$set(_vm.customer, "website", $$v)},expression:"customer.website"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Fax"}},[_c('validation-provider',{attrs:{"name":"fax"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Fax"},model:{value:(_vm.customer.fax),callback:function ($$v) {_vm.$set(_vm.customer, "fax", $$v)},expression:"customer.fax"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Adresse"}},[_c('validation-provider',{attrs:{"name":"address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"adresse"},model:{value:(_vm.customer.address),callback:function ($$v) {_vm.$set(_vm.customer, "address", $$v)},expression:"customer.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"MF/ CIN"}},[_c('validation-provider',{attrs:{"name":"fax"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"MF/ CIN"},model:{value:(_vm.customer.tax_identification_number),callback:function ($$v) {_vm.$set(_vm.customer, "tax_identification_number", $$v)},expression:"customer.tax_identification_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"},on:{"click":_vm.addCustomer}},[_vm._v(" Add ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" Cancel ")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }